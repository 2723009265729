import { css } from "goober";

export const ContentContainer = () => css`
  display: grid;
  grid-auto-rows: auto;
  gap: var(--sp-4);
  container-name: content-container;

  header {
    background: var(--shade7);
    border-top-left-radius: var(--br-lg);
    border-top-right-radius: var(--br-lg);
    border-bottom: 1px solid var(--shade3-25);
    padding: var(--sp-4) var(--sp-5);
  }

  .section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--sp-4);

    @container content-container (width <= 575px) {
      grid-template-columns: 1fr;
    }
  }

  .formula {
    color: var(--shade1);

    span {
      color: var(--shade0);
    }
  }
`;
