import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { getCurrentSet } from "@/game-tft/constants.mjs";
import { damageByRound, damageByUnit } from "@/game-tft/constants-damage.mjs";
import { ContentContainer } from "@/game-tft/GuideDamage.style.jsx";
import { TableRow } from "@/game-tft/GuideExp.style.jsx";
import { URLS } from "@/game-tft/guides-constants.mjs";
import TFTUnitIcon from "@/inline-assets/tft-unit.svg";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";

const DamageGuide = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <PageHeader
        title={t(
          "tft:guidesPlayerDamageFormula",
          "Player Damage Formula - Guides",
        )}
        links={URLS}
      />
      <div className={ContentContainer()}>
        <Card className="formula">
          {t("tft:playerDamage", "Player Damage")} ={" "}
          <span>
            {t("tft:damagePerSurvivingUnit", "Damage per surviving unit")}
          </span>{" "}
          + <span>{t("tft:damageByRound", "Damage by round")}</span>
        </Card>
        <div className="section">
          <Card padding="0">
            <header>
              {t("tft:damagePerSurvivingUnit", `Damage per surviving unit`)}
            </header>
            <TableRow>
              <div className="col">
                <span className="type-caption">
                  {t("common:units", "Units")}
                </span>
              </div>
              <div className="col">
                <span className="type-caption">
                  {t("common:damage", "Damage")}
                </span>
              </div>
            </TableRow>
            {damageByUnit.map((row, i) => {
              return (
                <TableRow key={i}>
                  <div className="col type-body2 title">
                    <TFTUnitIcon />
                    {t("tft:_units", "{{count}} Units", {
                      count: row.units,
                    })}
                  </div>
                  <div className="type-body2 col value">
                    {row.damage} {t("common:damage", "Damage")}
                  </div>
                </TableRow>
              );
            })}
          </Card>
          <Card padding="0">
            <header>{t("tft:damageByRound", "Damage by round")} </header>
            <TableRow>
              <div className="col">
                <span className="type-caption">{t("tft:round", "Round")}</span>
              </div>
              <div className="col">
                <span className="type-caption">
                  {t("common:damage", "Damage")}
                </span>
              </div>
            </TableRow>
            {damageByRound.map((row, i) => {
              return (
                <TableRow key={i}>
                  <div className="col type-body2 title">
                    {t("tft:roundNumber", "Round {{number}}", {
                      number: row.round,
                    })}
                  </div>
                  <div className="type-body2 col value">
                    {row.damage} {t("common:damage", "Damage")}
                  </div>
                </TableRow>
              );
            })}
          </Card>
        </div>
      </div>
    </PageContainer>
  );
};

export default DamageGuide;

export function meta(_params, searchParams) {
  let set = searchParams?.get("set") || getCurrentSet().replace("set", "");
  set = set.replace("_", ".");
  return {
    title: [
      "tft:meta.damage.title",
      "TFT Damage Formula - TFT Guides - Set {{set}}",
      { set },
    ],
    description: [
      "tft:meta.damage.description",
      "Unlock Winning Strategies with the Damage Formula in TFT! Optimize Comps, Builds, and Meta Using Key Stats.",
      { set },
    ],
  };
}
